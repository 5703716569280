<template>
  <div>
    <div class="share-network-list"></div>

    <div v-if="loadingRecoverSponsorCode">
      <div class="ml-2">
        <clip-loader
          :loading="loadingRecoverSponsorCode"
          color="primary"
        ></clip-loader>
      </div>
    </div>
    <div v-else class="m-4">
      <div class="pt-5 pb-5 justify-center">
        <div class="pb-3">
          <vx-card>
            <div slot="no-body" class="p-8">
              <div class="aps-benin-title-desc">
                <h1 class="center text-center aps-benin-description">
                  Bienvenue {{ user.person.firstname }} !
                </h1>
                <p
                  style="font-size: x-large;"
                  class="text-black center text-center aps-benin-description"
                >
                  <b>{{ user.person.forSociety }}</b>
                </p>
                <p
                  style="font-size: large;"
                  class="center text-center aps-benin-description"
                >
                  Ceci est votre compte intermédiaire professionnel. Vous
                  trouverez ci-dessous vos éléments de travail :
                </p>
              </div>
              <div class="mt-10 mb-10 aps-benin-share-link">
                <div class="flex" vs-align="center" vs-justify="center"></div>
                <vs-button @click="$router.push({ name: 'Offer' })">
                  Nos offres
                </vs-button>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="pb-3">
          <vx-card>
            <div slot="no-body" class="p-8">
              <div
                class="center text-center aps-benin-description aps-benin-title-desc"
              >
                <h1>
                  Votre lien de production
                </h1>
                <p style="font-size: large;">
                  Vous pouvez dès maintenant inviter vos clients et prospects à
                  découvrir nos offres
                </p>
              </div>

              <div class="mt-10 mb-10 aps-benin-share-link">
                <vs-row
                  vs-align="center"
                  vs-type="flex"
                  vs-justify="center"
                  vs-w="12"
                  class="mt-4"
                >
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                    <div class="aps-benin-input-container mr-4">
                      <strong>{{ link }}</strong>
                    </div>
                    <div>
                      <vs-button
                        v-clipboard:copy="link"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        id="bouton"
                        >Copier</vs-button
                      >
                    </div>
                  </vs-col>
                </vs-row>
              </div>

              <div class="aps-benin-share-btn">
                <div class="mb-4" style="font-size: large;">
                  <p>Partager sur les réseaux sociaux</p>
                </div>
                <div class="flex">
                  <ShareNetwork
                    class="m-2"
                    v-for="network in networks"
                    :network="network.network"
                    :key="network.network"
                    :style="{ backgroundColor: network.color }"
                    :url="link"
                    title=""
                    description=""
                    quote=""
                  >
                    <vs-button
                      :color="network.color"
                      icon-pack="feather"
                      :icon="network.icon"
                      >{{ network.name }}</vs-button
                    >
                  </ShareNetwork>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>

      <div class="pt-5 pb-5 flex justify-center">
        <h1>Statistiques</h1>
      </div>

      <div class="p-2">
        <vs-collapse>
          <vs-collapse-item class="aps-benin-collapse-item">
            <div slot="header">
              <div class="aps-benin-collapse-filter">Filtrage</div>
            </div>
            <div class="p-2">
              <vs-row vs-w="12" vs-align="center">
                <vs-col
                  class="pr-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <span class="pl-1 pr-1">Du</span>
                  <div
                    class="inputx w-full flex justify-around"
                    style="align-items: center;"
                  >
                    <vs-input
                      v-model="startDate"
                      type="date"
                      class="aps-benin-disable-input-date inputx w-full mb-2 mt-2"
                    />
                  </div>
                </vs-col>
                <vs-col
                  class="pr-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <span class="pl-1 pr-1">Au </span>
                  <div
                    class="inputx w-full flex justify-around "
                    style="align-items: center;"
                  >
                    <vs-input
                      type="date"
                      v-model="endDate"
                      :min="startDate"
                      class="aps-benin-disable-input-date inputx w-full mb-2 mt-2"
                    />
                  </div>
                </vs-col>
                <vs-col
                  class="pr-4 mb-2 mt-2"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <multiselect
                      v-model="product"
                      :options="allProduct"
                      :close-on-select="true"
                      placeholder="--Choisir un produit--"
                      label="label"
                      track-by="label"
                      :selectLabel="global.selectLabel"
                      :selectedLabel="global.selectedLabel"
                      :deselectLabel="global.deselectLabel"
                    >
                    </multiselect>
                  </div>
                </vs-col>
              </vs-row>
              <vs-row vs-w="12" vs-align="center" vs-justify="center">
                <vs-col
                  class="pt-4 pb-2"
                  vs-type="flex"
                  vs-align="center"
                  vs-justify="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <vs-button @click="resetFilter">
                    Réinitialiser
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
      <div v-if="loadingStatCard">
        <div class="ml-2">
          <clip-loader :loading="loadingStatCard" color="primary"></clip-loader>
        </div>
      </div>
      <div v-else class=" pb-5 ">
        <vs-row vs-w="12" vs-align="center">
          <vs-col
            class="p-2"
            vs-lg="4"
            vs-md="4"
            vs-sm="6"
            :key="stat.label"
            v-for="stat in statsList"
          >
            <statistics-card-line
              hideChart
              icon="BarChart2Icon"
              icon-right
              :statistic="stat.value"
              :statisticTitle="stat.label"
            />
          </vs-col>
        </vs-row>
      </div>

 
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import VueSocialSharing from "vue-social-sharing";
import Multiselect from "vue-multiselect";
import global from "@/config/global";

export default {
  data() {
    return {
      isResetFilter: false,
      loadingStatCard :false,
      description: "Votre lien de parrainage de APS Bénin: ",
      link: "",
      statsList: [],
      productList: [],
      product: "",
      loadingDownloadFile: false,
      loadingRecoverSponsorCode: false,
      error: false,
      networks: [
        {
          network: "whatsapp",
          name: "WhatsApp",
          icon: "icon-phone",
          color: "#25d366"
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: "icon-facebook",
          color: "#1877f2"
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "icon-twitter",
          color: "#1da1f2"
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "icon-linkedin",
          color: "#007bb5"
        },
        {
          network: "email",
          name: "Email",
          icon: "icon-mail",
          color: "#333333"
        }
      ],
      startDate: "",
      endDate: "",
      global: {}
    };
  },
  components: {
    StatisticsCardLine,
    ClipLoader,
    VueSocialSharing,
    Multiselect
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("general", ["allProduct"])
  },
  created() {
    this.global = global;
  },
  mounted() {
    this.link = `https://tarification.aps-benin.com/?code=${this.user.userCode}`;
    this.description = this.link;
    this.getStats({
      userId: this.user.id,
      userCode: this.user.userCode,
      startDate: this.startDate,
      endDate: this.endDate,
      productName: this.product.id ? this.product.id : "",
      by: "me"
    });
  },
  methods: {
    ...mapActions("general", ["applyParrainStats"]),

    async getStats(data) {
      let userStat = { ...data };

      console.log(data);
      this.loadingStatCard = true;
      try {
        let stats = await this.applyParrainStats(userStat);
        this.statsList = stats.stats;
        this.productList = stats.products;
        this.loadingStatCard = false;
        this.isResetFilter = false;
        this.error = false;
      } catch (err) {
        this.isResetFilter = false;
        this.loadingStatCard = false;
        this.error = true;
        this.errorMsg = "";
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            this.acceptAlert(
              "danger",
              "STATISTIQUES",
              "Une erreur s'est produite"
            );
          }
        }
      }
    },

    resetFilter() {
      this.isResetFilter = true;
      this.startDate = "";
      this.endDate = "";
      this.product = "";
      this.getStats({
        userId: this.user.id,
        userCode: this.user.userCode,
        startDate: this.startDate,
        endDate: this.endDate,
        productName: this.product.id ? this.product.id : "",
        by: "me"
      });
    },

    onCopy() {
      this.$vs.notify({
        title: "Succès",
        text: "Lien copié avec succès",
        color: "success",
        iconPack: "feather",
        position: "bottom-right",
        icon: "icon-check-circle"
      });
      document.getElementById("bouton").innerHTML = "Copié";
    },

    onError() {
      this.$vs.notify({
        title: "Echec",
        text: "Erreur lors de la copie du lien",
        color: "danger",
        iconPack: "feather",
        position: "bottom-right",
        icon: "icon-alert-circle"
      });
    },

    acceptAlert(color, title, text) {
      this.$vs.notify({ color, title, text });
    }
  },
  watch: {
    product() {
      if (!this.isResetFilter) {
        this.getStats({
          userId: this.user.id,
          userCode: this.user.userCode,
          startDate: this.startDate,
          endDate: this.endDate,
          productName: this.product.id ? this.product.id : "",
          by: "me"
        });
      }
    },
    startDate() {
      if (!this.isResetFilter) {
        this.getStats({
          userId: this.user.id,
          userCode: this.user.userCode,
          startDate: this.startDate,
          endDate: this.endDate,
          productName: this.product.id ? this.product.id : "",
          by: "me"
        });
      }
    },
    endDate() {
      if (!this.isResetFilter) {
        this.getStats({
          userId: this.user.id,
          userCode: this.user.userCode,
          startDate: this.startDate,
          endDate: this.endDate,
          productName: this.product.id ? this.product.id : "",
          by: "me"
        });
      }
    }
  }
};
</script>

<style>
/*.vs-card_title {
  margin-top: 39px;
  float: center;
  opacity: 0.7;
}
.inline-flex-input {
  width: 300px;
  height: 8px;
  float: left;
  margin-left: 32%;
}
h1 {
  opacity: 0.6;
  font-family: "Bookman Old Style";
  font-size: 40px;
}

h3 {
  opacity: 0.4;
  font-family: "Bookman Old Style";
  font-size: 25px;
}
*/

.aps-benin-share-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aps-benin-share-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aps-benin-input-container {
  border: 2px solid #a0a0a0;
  text-align: center;
  padding: 10px;
  padding-bottom: 10px;
}
.aps-benin-title-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vs-con-input {
  width: 100%;
}

.aps-benin-description {
  padding: 0 12vw;
}

.share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}
</style>
